import { Container, Row, Col } from "react-bootstrap";
import styles from "./app.module.css";
import Menu from "./Components/Menu";
import TopMenu from "./Components/TopMenu";
import Logo from "./images/saaselogo.png";
import ViewCustomers from "./Pages/ViewCustomers";
import Prizes from "./Pages/Prizes";
import ShakeLog from "./Pages/ShakeLog";

import WhatsOn from "./Pages/WhatsOn";
import Events from "./Pages/Events";
import Offers from "./Pages/Offers";
import Videos from "./Pages/DrinkMenu";

import { Switch, Route } from "react-router-dom";
import Notifications from "./Pages/Notifications";
import OpeningTimes from "./Pages/OpeningTimes";
import FoodMenu from "./Pages/FoodMenu";
import DrinkMenu from "./Pages/DrinkMenu";
import Competitions from "./Pages/Competitions";
import Entries from "./Pages/Entries";
import DrinkPages from "./Pages/DrinkPages";
import AboutUs from "./Pages/AboutUs";
import FoodPages from "./Pages/FoodPages";
import Terms from "./Pages/Terms";

function App() {
  return (
    <Container className={styles["main-container"]} fluid>
      <Row className={styles["content-row"]}>
        <div className="d-none d-md-block">
          <Col
            className={styles["menu-col"]}
            style={{ height: "100%", display: "flex" }}
          >
            <Container
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <Row className={styles["logo-row"]}>
                <img src={Logo} />
              </Row>

              <Row>
                <Menu
                  didSelect={() => null}
                  activeKey={"0"}
                  selectedTab={0}
                  activeChild={"1"}
                />
              </Row>
              <Row
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                v0.0.11
              </Row>
            </Container>
          </Col>
        </div>
        <div className="d-block d-md-none">
          <TopMenu />
        </div>
        <Col className={styles["content-col"]}>
          <Switch>
            <Route path="/" exact>
              <ViewCustomers />
            </Route>
            <Route path="/customers" exact>
              <ViewCustomers />
            </Route>
            <Route path="/shakelog" exact>
              <ShakeLog />
            </Route>
            <Route path="/prizes" exact>
              <Prizes />
            </Route>
            <Route path="/whatson" exact>
              <WhatsOn />
            </Route>
            <Route path="/events" exact>
              <Events />
            </Route>
            <Route path="/offers" exact>
              <Offers />
            </Route>
            <Route path="/drinks" exact>
              <DrinkMenu />
            </Route>
            <Route path="/drinkpages" exact>
              <DrinkPages />
            </Route>
            <Route path="/foodpages" exact>
              <FoodPages />
            </Route>
            <Route path="/food" exact>
              <FoodMenu />
            </Route>
            <Route path="/opening" exact>
              <OpeningTimes />
            </Route>
            <Route path="/notification" exact>
              <Notifications />
            </Route>
            <Route path="/competitions" exact>
              <Competitions />
            </Route>
            <Route path="/entries" exact>
              <Entries />
            </Route>
            <Route path="/aboutus" exact>
              <AboutUs />
            </Route>
            <Route path="/terms" exact>
              <Terms />
            </Route>
            <Route path="*">
              <div>Not Found</div>
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
