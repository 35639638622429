import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";
import DayDropDownPreview from "../Components/Tables/CustomComponents/DayDropDownPreview";
import OfferTimes from "../Components/Tables/CustomComponents/OfferTimes";
import OfferTimesPreview from "../Components/Tables/CustomComponents/OfferTimesPreview";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
  DayDropDownPreview: DayDropDownPreview,
  OfferTimes: OfferTimes,
  OfferTimesPreview: OfferTimesPreview,
};

function Offers() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Title",
      value: "title",
      editable: true,
      place_holder: "Bogof",
      modal_component: "Text",
    },
    {
      title: "Times",
      value: "times",
      editable: true,
      place_holder: "",
      custom_component: "OfferTimesPreview",
      modal_component: "OfferTimes",
    },
    {
      title: "Subtitle",
      value: "subtitle",
      editable: true,
      place_holder: "Subtitle",
      modal_component: "Text",
    },
    {
      title: "Button Text",
      value: "button_text",
      editable: true,
      place_holder: "REDEEM NOW",
      modal_component: "Text",
    },

    {
      title: "Image",
      value: "image",
      editable: true,
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
    {
      title: "Aspect Ratio",
      value: "aspect_ratio",
      editable: true,
      place_holder: "1",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Title",
        value: "title",
        editable: true,
        place_holder: "Bogof",
        modal_component: "Text",
      },
      {
        title: "Times",
        value: "times",
        editable: true,
        place_holder: "",
        modal_component: "OfferTimes",
        custom_component: "OfferTimesPreview",
      },
      {
        title: "Subtitle",
        value: "subtitle",
        editable: true,
        place_holder: "Subtitle",
        modal_component: "Text",
      },

      {
        title: "Button Text",
        value: "button_text",
        editable: true,
        place_holder: "REDEEM NOW",
        modal_component: "Text",
      },

      {
        title: "Image",
        value: "image",
        editable: true,
        modal_component: "Image",
        custom_component: "Image",
      },
      {
        title: "Aspect Ratio",
        value: "aspect_ratio",
        editable: true,
        place_holder: "1",
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Offers"}
            buttonText={"Add Offer"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deleteoffer.php"}
            getEP={"/offers.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addoffer.php"}
          deleteEP={"/deleteoffer.php"}
          editEP={"/updateoffers.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default Offers;
