import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axiosConfig from "../../../axiosConf.js";

function CocktailCategoryDropDown(props) {
  const [selectedValue, setSelectedValue] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    var currentValue = props.data.current_value;

    console.log(currentValue);
    props.value_changed({ value: currentValue, index: props.index });
    setSelectedValue(currentValue);
  }, []);

  useEffect(() => {
    console.log(props);
    try {
      axiosConfig
        .get("/getdrinkpagesdropdown.php")
        .then((response) => {
          console.log(response);
          setOptions(response.data);
        })
        .catch((error) => {
          // setError(error.response?.data);
        });
    } catch (error) {
      //setError("An error occured.");
    }
  }, []);

  function setSelected(value) {
    console.log(selectedValue + " + " + value);
    if (selectedValue == value) {
      return true;
    } else {
      return false;
    }
  }

  function valueChanged(e) {
    console.log(e);
    props.value_changed({ value: e.target.value, index: props.index });
    setSelectedValue(e.target.value);
  }

  return (
    <Form.Control
      id={selectedValue}
      onChange={(e) => [valueChanged(e)]}
      as="select"
    >
      <option selected={setSelected()} value={""}>
        Select a Category
      </option>
      {options?.map(function (object, i) {
        return (
          <option selected={setSelected(object.id)} value={object.id}>
            {object.title}
          </option>
        );
      })}
    </Form.Control>
  );
}
export default CocktailCategoryDropDown;
