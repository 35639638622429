import React from "react";
import { Container, Row } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./viewcustomer.module.css";
import Claimed from "../Components/Tables/CustomComponents/Claimed";

function ShakeLog() {
  const components = {
    Claimed: Claimed,
  };

  const headers = [
    { title: "Name", value: "name", editable: false },
    { title: "Prize", value: "prize", editable: false },
    {
      title: "Claimed",
      value: "claimed",
      editable: false,
      custom_component: "Claimed",
    },
    { title: "Time", value: "time", editable: false },
  ];
  return (
    <Container className={styles["main-container"]} fluid>
      <Row>
        <Header1 title={"Shake Logs"} />
      </Row>
      <Row className={styles["table-row"]}>
        <Table
          components={components}
          headers={headers}
          getEP={"/shakelog.php"}
        />
      </Row>
    </Container>
  );
}
export default ShakeLog;
