import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";
import CheckBox from "../Components/Tables/CustomComponents/CheckBox";
import TimestampPreview from "../Components/Tables/CustomComponents/TimestampPreview";
import DateTimePickerField from "../Components/Tables/CustomComponents/DateTimePickerField";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
  CheckBox: CheckBox,
  TimestampPreview: TimestampPreview,
  DateTimePickerField: DateTimePickerField,
};

function Events() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Title",
      value: "title",
      editable: true,
      place_holder: "Bogof",
      modal_component: "Text",
    },
    {
      title: "Sub Title",
      value: "subtitle",
      editable: true,
      place_holder: "Bogof",
      modal_component: "Text",
    },
    {
      title: "Date",
      value: "date",
      editable: true,
      place_holder: "Date",
      modal_component: "DateTimePickerField",
      custom_component: "TimestampPreview",
    },

    {
      title: "Image",
      value: "image",
      editable: true,
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Title",
        value: "title",
        editable: true,
        place_holder: "Bogof",
        modal_component: "Text",
      },
      {
        title: "Sub Title",
        value: "subtitle",
        editable: true,
        place_holder: "Bogof",
        modal_component: "Text",
      },
      {
        title: "Date",
        value: "date",
        editable: true,
        place_holder: "Date",
        modal_component: "DateTimePickerField",
      },

      {
        title: "Image",
        value: "image",
        editable: true,
        modal_component: "Image",
        custom_component: "ImagePreview",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Featured Events"}
            buttonText={"Add Event"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deleteevent.php"}
            getEP={"/events.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addevent.php"}
          deleteEP={"/deleteevent.php"}
          editEP={"/updateevents.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default Events;
