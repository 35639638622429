function OfferTimesPreview(props) {
  function formatDay(day) {
    switch (day) {
      case "1":
        return "Monday";
        break;
      case "2":
        return "Tuesday";
        break;
      case "3":
        return "Wednesday";
        break;
      case "4":
        return "Thursday";
        break;
      case "5":
        return "Friday";
        break;
      case "6":
        return "Saturday";
        break;
      case "7":
        return "Sunday";
        break;

      default:
        break;
    }
  }

  return (
    <div>
      {props.data &&
        JSON.parse(props.data)?.map(function (item, i) {
          return (
            <div style={{ fontSize: 10, fontWeight: 600 }}>
              - {formatDay(item.start_day)} {item.start_time} to{" "}
              {formatDay(item.end_day)} {item.end_time}
            </div>
          );
        })}
    </div>
  );
}
export default OfferTimesPreview;
