import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";
import DayDropDownAll from "../Components/Tables/CustomComponents/DayDropDownAll";

const components = {
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  DayDropDownAll: DayDropDownAll,
};

function Prizes() {
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Prize",
      value: "prize",
      editable: true,
      place_holder: "Free Shot!",
      modal_component: "Text",
    },

    {
      title: "Image",
      value: "image",
      editable: true,
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
    {
      title: "Percentage Chance",
      value: "percentage",
      editable: false,
      place_holder: "10%",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Day",
        place_holder: "",
        required: true,
        modal_component: "DayDropDownAll",
      },
      {
        title: "Prize Name",
        place_holder: "Free Shot",
        required: true,
        modal_component: "Text",
      },

      {
        title: "Image",
        place_holder: "",
        required: true,
        modal_component: "Image",
      },
      {
        title: "Percentage Chance",
        place_holder: "10.00",
        required: true,
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Prizes"}
            buttonText={"Add Prize"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["dropdown-row"]}>
          <DropdownButton
            className={styles["dropdown-dark"]}
            title={selectedDay}
          >
            <Dropdown.Item onClick={() => setSelectedDay("Monday")}>
              Monday
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDay("Tuesday")}>
              Tuesday
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDay("Wednesday")}>
              Wednesday
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDay("Thursday")}>
              Thursday
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDay("Friday")}>
              Friday
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDay("Saturday")}>
              Saturday
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDay("Sunday")}>
              Sunday
            </Dropdown.Item>
          </DropdownButton>
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deleteprize.php"}
            getEP={"/prizes.php"}
            params={"&day=" + selectedDay.toLowerCase()}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addprize.php"}
          deleteEP={"/deleteprize.php"}
          editEP={"/updateprize.php"}
          postData={{ day: selectedDay }}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default Prizes;
