function CheckBox(props) {
  function valueChanged(e) {
    console.log(e.target.checked);
    props.value_changed({ value: e.target.checked, index: props.index });
  }

  return (
    <input
      type="checkbox"
      checked={props.data.current_value}
      name="promo"
      onChange={valueChanged}
    />
  );
}

export default CheckBox;
