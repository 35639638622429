function Privacy() {
  return (
    <div>
      <p>
        This Privacy Policy sets out how we, Sidewalk Ltd. collect, store and
        use information about you when you use or interact with our website, and
        where we otherwise obtain or collect information about you. This Privacy
        Policy is effective from 1st January 2024.
      </p>

      <p>Contents</p>

      <p>&middot; Summary</p>

      <p>&middot; Our details</p>

      <p>&middot; Information we collect when you visit our website</p>

      <p>&middot; Information we collect when you contact us</p>

      <p>&middot; Information we collect when you interact with our website</p>

      <p>
        &middot; How we collect or obtain information about you from third
        parties
      </p>

      <p>&middot; Disclosure and additional uses of your information</p>

      <p>&middot; How long we retain your information</p>

      <p>&middot; How we secure your information</p>

      <p>&middot; Your rights in relation to your information</p>

      <p>
        &middot; Your right to object to the processing of your information for
        certain purposes
      </p>

      <p>&middot; Sensitive Personal Information</p>

      <p>&middot; Changes to our Privacy Policy</p>

      <p>&middot; Children&rsquo;s Privacy</p>

      <p>&middot; Copyright, credit and logo</p>

      <p></p>

      <p>Summary</p>

      <p>
        This section summarises how we obtain, store and use information about
        you. It is intended to provide a very general overview only. It is not
        complete in and of itself and it must be read in conjunction with the
        corresponding full sections of this Privacy Policy.
      </p>

      <p>&middot; Data controller: Sidewalk Ltd.</p>

      <p>&middot; How we collect or obtain information about you:</p>

      <p>
        o when you provide it to us (e.g. by contacting us or enquiring about a
        booking)
      </p>

      <p>
        o from your use of our website, using cookies and occasionally from
        third parties
      </p>

      <p>
        &middot; Information we collect: name &amp; contact details (email
        address &amp; mobile number)
      </p>

      <p>
        &middot; How we use your information: for administrative and business
        purposes (particularly to contact you and to send out marketing emails
        providing you with information on upcoming events &amp; offers or
        news/changes to the venue.
      </p>

      <p>
        &middot; Disclosure of your information to third parties: only to the
        extent necessary to run our business and to keep you informed/promote
        upcoming events in our sister venues.
      </p>

      <p>
        &middot; Do we sell your information to third parties (other than in the
        course of a business sale or purchase or similar event): No
      </p>

      <p>
        &middot; How long we retain your information: for no longer than
        necessary, taking into account any legal obligations we have (e.g. to
        maintain records for tax purposes), any other legal basis we have for
        using your information. Your contact email address and mobile are kept
        until the moment you request your information to be removed from our
        system.
      </p>

      <p>
        &middot; How we secure your information: using appropriate technical and
        organisational measures such as storing your information on our secure
        servers, only granting access to your information where necessary and
        assigning a maximum of 3 person(s) within our organisation to access the
        data.
      </p>

      <p>
        &middot; Use of cookies: The only cookies we use on our website are
        third party which stem from our website service provider.
      </p>

      <p>
        &middot; Use of automated decision making and profiling: we do not use
        automated decision making or profiling.
      </p>

      <p>&middot; Your rights in relation to your information</p>

      <p>
        o to access your information and to receive information about its use
      </p>

      <p>o to have your information corrected and/or completed</p>

      <p>o to have your information deleted</p>

      <p>o to restrict the use of your information</p>

      <p>o to receive your information in a portable format</p>

      <p>o to object to the use of your information</p>

      <p>o to withdraw your consent to the use of your information</p>

      <p>o to complain to a supervisory authority</p>

      <p>
        &middot; Sensitive personal information: we do not knowingly or
        intentionally collect what is commonly referred to as &lsquo;sensitive
        personal information&rsquo;. Please do not submit sensitive personal
        information about you to us. For more information, please see the main
        section below entitled Sensitive Personal Information.
      </p>

      <p></p>

      <p>Our details</p>

      <p>
        The data controller in respect of our website is Sidewalk Ltd. You can
        contact the data controller by sending an email to
        hello@sidewalkbar.co.uk
      </p>

      <p>
        If you have any questions about this Privacy Policy, please contact the
        data controller.
      </p>

      <p></p>

      <p>Information we collect when you visit our website</p>

      <p>
        We collect and use information from website visitors in accordance with
        this section and the section entitled Disclosure and additional uses of
        your information.
      </p>

      <p></p>

      <p>Web server log information</p>

      <p>
        Our website server automatically logs the IP address you use to access
        our website as well as other information about your visit such as the
        pages accessed, information requested, the date and time of the request,
        the source of your access to our website (e.g. the website or URL (link)
        which referred you to our website), and your browser version and
        operating system.
      </p>

      <p>
        Our server is located in the United States and, accordingly, your
        information is transferred outside the European Economic Area (EEA). For
        further information and information on the safeguards used, please see
        the section of this privacy policy entitled Transfers of your
        information outside the European Economic Area.
      </p>

      <p>Use of website server log information for IT security purposes</p>

      <p>
        Our third party hosting provider collect(s) and store(s) server logs to
        ensure network and IT security and so that the server and website remain
        uncompromised. This includes analysing log files to help identify and
        prevent unauthorised access to our network, the distribution of
        malicious code, denial of services attacks and other cyber attacks, by
        detecting unusual or suspicious activity.
      </p>

      <p>
        Unless we are investigating suspicious or potential criminal activity,
        we do not make, nor do we allow our hosting provider to make, any
        attempt to identify you from the information collected via server logs.
      </p>

      <p>
        Legal basis for processing: compliance with a legal obligation to which
        we are subject (Article 6(1)(c) of the General Data Protection
        Regulation). Legal obligation: we have a legal obligation to implement
        appropriate technical and organisational measures to ensure a level of
        security appropriate to the risk of our processing of information about
        individuals. Recording access to our website using server log files is
        such a measure.
      </p>

      <p>
        Legal basis for processing: legitimate interests (Article 6(1)(f) of the
        General Data Protection Regulation). Legitimate interests: we our third
        party provider, Squarespace, have a legitimate interest in using your
        information for the purposes of ensuring network and information
        security.{" "}
      </p>

      <p>Cookies and similar technologies</p>

      <p>Information we collect when you contact us</p>

      <p>
        We collect and use information from individuals who contact us in
        accordance with this section and the section entitled Disclosure and
        additional uses of your information.
      </p>

      <p>Email</p>

      <p>
        When you send an email to the email address displayed on our website we
        collect your email address and any other information you provide in that
        email (such as your name &amp; telephone number).
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interest(s):
        responding to enquiries and messages we receive and keeping records of
        correspondence.
      </p>

      <p>
        Legal basis for processing: necessary to perform a contract or to take
        steps at your request to enter into a contract (Article 6(1)(b) of the
        General Data Protection Regulation). Reason why necessary to perform a
        contract: where your message relates to us providing you with goods or
        services or taking steps at your request prior to providing you with our
        goods and services (for example, providing you with information about
        such goods and services), we will process your information in order to
        do so).
      </p>

      <p>Transfer and storage of your information</p>

      <p>
        We use a third party email provider to store emails you send us. Our
        third party email provider is Gmail .Their privacy policy is available
        here: https://policies.google.com/privacy
      </p>

      <p>
        Emails you send us will be stored within the European Economic Area on
        our third party email provider&rsquo;s servers. For further information
        please see the section of this privacy policy entitled Transfers of your
        information outside the European Economic Area.][1]
      </p>

      <p>Contact form</p>

      <p>
        When you contact us using our contact form, we collect your name, mobile
        number &amp; email address. We also collect any other information you
        provide to us when you complete the contact form[, including any
        optional information, such as an additional message you write to us and
        any content which is sent in this message.
      </p>

      <p>
        If you do not provide the mandatory information required by our contact
        form, you will not be able to submit the contact form and we will not
        receive your enquiry.
      </p>

      <p>
        If you do not supply the optional information required by our contact
        form, we may not be able to help you with your enquiry, as this section
        of the form helps us understand your request.
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interest(s):
        responding to enquiries and messages we receive and keeping records of
        correspondence.
      </p>

      <p>
        Legal basis for processing: necessary to perform a contract or to take
        steps at your request to enter into a contract (Article 6(1)(b) of the
        General Data Protection Regulation). Reason why necessary to perform a
        contract: where your message relates to us providing you with goods or
        services or taking steps at your request prior to providing you with our
        goods and services (for example, providing you with information about
        such goods and services), we will process your information in order to
        do so).
      </p>

      <p>Transfer and storage of your information</p>

      <p>
        Messages you send us via our contact form will be stored within the
        European Economic Area on our [third party, Gmail servers across Europe.
      </p>

      <p>
        Our third party email provider is Gmail. Their privacy policy is
        available here:
      </p>

      <p>https://policies.google.com/privacy</p>

      <p>Phone</p>

      <p>
        When you contact us by phone, we collect your phone number should you
        request a call back and any information provided to us during your
        conversation with us, we do not record phone calls.
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation) Legitimate interest(s):
        responding to enquiries and messages we receive and keeping records of
        correspondence.
      </p>

      <p>
        Legal basis for processing: necessary to perform a contract or to take
        steps at your request to enter into a contract (Article 6(1)(b) of the
        General Data Protection Regulation). Reason why necessary to perform a
        contract: where your message relates to us providing you with goods or
        services or taking steps at your request prior to providing you with our
        goods and services (for example, providing you with information about
        such goods and services), we will process your information in order to
        do so).
      </p>

      <p>Transfer and storage of your information</p>

      <p>
        Information about your call, such as your phone number and the date and
        time of your call, is processed by our third party telephone service
        provider Midland Communications. Their privacy policy is available here:
        https://www.midlandtelecom.co.uk/privacy-policy
      </p>

      <p>Post</p>

      <p>
        If you contact us by post, we will collect any information you provide
        to us in any postal communications you send us.
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation) Legitimate interest(s):
        responding to enquiries and messages we receive and keeping records of
        correspondence.
      </p>

      <p>
        Legal basis for processing: necessary to perform a contract or to take
        steps at your request to enter into a contract (Article 6(1)(b) of the
        General Data Protection Regulation). Reason why necessary to perform a
        contract: where your message relates to us providing you with goods or
        services or taking steps at your request prior to providing you with our
        goods and services (for example, providing you with information about
        such goods and services), we will process your information in order to
        do so).
      </p>

      <p>Information we collect when you interact with our website</p>

      <p>
        We collect and use information from individuals who interact with
        particular features of our website in accordance with this section and
        the section entitled Disclosure and additional uses of your information.
      </p>

      <p>E-Newsletter</p>

      <p>
        When you sign up for our e-newsletter on our website, we collect your
        name and email address.
      </p>

      <p>
        Legal basis for processing: your consent (Article 6(1)(a) of the General
        Data Protection Regulation). Consent: you give your consent to us
        sending you our e-newsletter by signing up to receive it using the steps
        described above.
      </p>

      <p>Transfer and storage of your information</p>

      <p>
        Information you submit to subscribe for our e-newsletter will be stored
        within the European Economic Area on our servers in United Kingdom.
      </p>

      <p>Registering on our app</p>

      <p>
        When you register and create an account on our app, we collect the
        following information: name, email address, phone number and any other
        information you provide to us when you complete the registration form.
        By registering on the app, you agree for your data to be used for
        marketing purposes.
      </p>

      <p>
        If you do not provide the mandatory information required by the
        registration form, you will not be able to register or create an account
        on our website.
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interest:
        registering and administering accounts on our website to [insert purpose
        e.g. to provide access to content] [and facilitate the running and
        operation of our business.
      </p>

      <p>Transfer and storage of your information</p>

      <p>
        Information you submit to us via the registration form on our website
        will be stored within the European Economic Area on our servers in
        United Kingdom.
      </p>

      <p>Information collected or obtained from third parties</p>

      <p>
        This section sets out how we obtain or collect information about you
        from third parties.
      </p>

      <p>Information received from third parties</p>

      <p>We do not receive information about you from third parties.</p>

      <p>
        It is also possible that third parties with whom we have had no prior
        contact may provide us with information about you.
      </p>

      <p>Where we receive information about you in error</p>

      <p>
        If we receive information about you from a third party in error and/or
        we do not have a legal basis for processing that information, we will
        delete your information.
      </p>

      <p>Disclosure and additional uses of your information</p>

      <p>
        This section sets out the circumstances in which will disclose
        information about you to third parties and any additional purposes for
        which we use your information.
      </p>

      <p>Disclosure of your information to service providers</p>

      <p>
        We use a number of third parties to provide us with services which are
        necessary to run our business or to assist us with running our business
        and who process your information for us on our behalf.
      </p>

      <p>
        We do not display the identities of our service providers publicly by
        name for security and competitive reasons. If you would like further
        information about the identities of our service providers, however,
        please contact us directly by email and we will provide you with such
        information where you have a legitimate reason for requesting it (where
        we have shared your information with such service providers, for
        example).
      </p>

      <p>Disclosure of your information to other third parties</p>

      <p>
        We do not display the identities of the other third parties we may share
        information with by name for security and competitive reasons. If you
        would like further information about the identities of such third
        parties, however, please contact us directly by email and we will
        provide you with such information where you have a legitimate reason for
        requesting it (where we have shared your information with such third
        parties, for example).
      </p>

      <p>Disclosure and use of your information for legal reasons</p>

      <p>
        Indicating possible criminal acts or threats to public security to a
        competent authority
      </p>

      <p>
        If we suspect that criminal or potential criminal conduct has been
        occurred, we will in certain circumstances need to contact an
        appropriate authority, such as the police. This could be the case, for
        instance, if we suspect that we fraud or a cyber crime has been
        committed or if we receive threats or malicious communications towards
        us or third parties.
      </p>

      <p>
        We will generally only need to process your information for this purpose
        if you were involved or affected by such an incident in some way.
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interests:
        preventing crime or suspected criminal activity (such as fraud).
      </p>

      <p>
        In connection with the enforcement or potential enforcement our legal
        rights
      </p>

      <p>
        We will use your information in connection with the enforcement or
        potential enforcement of our legal rights, including, for example,
        sharing information with debt collection agencies if you do not pay
        amounts owed to us when you are contractually obliged to do so. Our
        legal rights may be contractual (where we have entered into a contract
        with you) or non-contractual (such as legal rights that we have under
        copyright law or tort law).
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interest: enforcing
        our legal rights and taking steps to enforce our legal rights.
      </p>

      <p>
        In connection with a legal or potential legal dispute or proceedings
      </p>

      <p>
        We may need to use your information if we are involved in a dispute with
        you or a third party for example, either to resolve the dispute or as
        part of any mediation, arbitration or court resolution or similar
        process.
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interest(s):
        resolving disputes and potential disputes.
      </p>

      <p>
        For ongoing compliance with laws, regulations and other legal
        requirements
      </p>

      <p>
        We will use and process your information in order to comply with legal
        obligations to which we are subject. For example, we may need to
        disclose your information pursuant to a court order or subpoena if we
        receive one.
      </p>

      <p>
        Legal basis for processing: compliance with a legal obligation (Article
        6(1)(c) of the General Data Protection Regulation). Legal obligation(s):
        legal obligations to disclose information which are part of the laws of
        England and Wales or if they have been integrated into the United
        Kingdom&rsquo;s legal framework (for example in the form of an
        international agreement which the United Kingdom has signed).
      </p>

      <p>
        Legal basis for processing: our legitimate interests (Article 6(1)(f) of
        the General Data Protection Regulation). Legitimate interest: where the
        legal obligations are part of the laws of another country and have not
        been integrated into the United Kingdom&rsquo;s legal framework, we have
        a legitimate interest in complying with these obligations.
      </p>

      <p>How long we retain your information</p>

      <p>
        This section sets out how long we retain your information. We have set
        out specific retention periods where possible. Where that has not been
        possible, we have set out the criteria we use to determine the retention
        period.
      </p>

      <p>Retention periods</p>

      <p>
        Server log information: we retain information on our server logs for
        [insert period for which you retain information from server logs.
      </p>

      <p>
        Order information: when you place an order for goods and services, we
        retain that information for six years following the end of the financial
        year in which you placed your order, in accordance with our legal
        obligation to keep records for tax purposes.
      </p>

      <p>
        Correspondence and enquiries: when you make an enquiry or correspond
        with us for any reason, whether by email or via our contact form or by
        phone, we will retain your information for as long as it takes to
        respond to and resolve your enquiry, and for 3 further months, after
        which point we will delete your information.
      </p>

      <p>
        E-Newsletter: we retain the information you used to sign up for our
        e-newsletter for as long as you remain subscribed (i.e. you do not
        unsubscribe) or if we decide to cancel our e-newsletter service,
        whichever comes earlier.
      </p>

      <p>Criteria for determining retention periods</p>

      <p>
        In any other circumstances, we will retain your information for no
        longer than necessary, taking into account the following:
      </p>

      <p>
        &middot; the purpose(s) and use of your information both now and in the
        future (such as whether it is necessary to continue to store that
        information in order to continue to perform our obligations under a
        contract with you or to contact you in the future);
      </p>

      <p>
        &middot; whether we have any legal obligation to continue to process
        your information (such as any record-keeping obligations imposed by
        relevant law or regulation);
      </p>

      <p>
        &middot; whether we have any legal basis to continue to process your
        information (such as your consent);
      </p>

      <p>
        &middot; how valuable your information is (both now and in the future);
      </p>

      <p>
        &middot; any relevant agreed industry practices on how long information
        should be retained;
      </p>

      <p>
        &middot; the levels of risk, cost and liability involved with us
        continuing to hold the information;
      </p>

      <p>
        &middot; how hard it is to ensure that the information can be kept up to
        date and accurate; and
      </p>

      <p>How we secure your information</p>

      <p>
        We take appropriate technical and organisational measures to secure your
        information and to protect it against unauthorised or unlawful use and
        accidental loss or destruction, including:
      </p>

      <p>
        &middot; only sharing and providing access to your information to the
        minimum extent necessary, subject to confidentiality restrictions where
        appropriate, and on an anonymised basis wherever possible;
      </p>

      <p>&middot; using secure servers to store your information;</p>

      <p>
        &middot; verifying the identity of any individual who requests access to
        information prior to granting them access to information;
      </p>

      <p>
        &middot; using Secure Sockets Layer (SSL) software to encrypt any
        information you submit to us via any forms on our website;
      </p>

      <p>
        &middot; only transferring your information via closed system or
        encrypted data transfers.
      </p>

      <p>Transmission of information to us by email</p>

      <p>
        Transmission of information over the internet is not entirely secure,
        and if you submit any information to us over the internet (whether by
        email, via our website or any other means), you do so entirely at your
        own risk.
      </p>

      <p>
        We cannot be responsible for any costs, expenses, loss of profits, harm
        to reputation, damages, liabilities or any other form of loss or damage
        suffered by you as a result of your decision to transmit information to
        us by such means.
      </p>

      <p></p>

      <p>Your rights in relation to your information</p>

      <p>
        Subject to certain limitations on certain rights, you have the following
        rights in relation to your information, which you can exercise by
        sending an email to hello@sidewalkbar.co.uk:
      </p>

      <p>
        &middot; to request access to your information and information related
        to our use and processing of your information;
      </p>

      <p>&middot; to request the correction or deletion of your information;</p>

      <p>&middot; to request that we restrict our use of your information;</p>

      <p>
        &middot; to receive information which you have provided to us in a
        structured, commonly used and machine-readable format (e.g. a CSV file)
        and the right to have that information transferred to another data
        controller (including a third party data controller);
      </p>

      <p>
        &middot; to object to the processing of your information for certain
        purposes (for further information, see the section below entitled Your
        right to object to the processing of your information for certain
        purposes); and
      </p>

      <p>
        &middot; to withdraw your consent to our use of your information at any
        time where we rely on your consent to use or process that information.
        Please note that if you withdraw your consent, this will not affect the
        lawfulness of our use and processing of your information on the basis of
        your consent before the point in time when you withdraw your consent.
      </p>

      <p>
        In accordance with Article 77 of the General Data Protection Regulation,
        you also have the right to lodge a complaint with a supervisory
        authority, in particular in the Member State of your habitual residence,
        place of work or of an alleged infringement of the General Data
        Protection Regulation.
      </p>

      <p>
        For the purposes of the UK, the supervisory authority is the Information
        Commissioner&rsquo;s Office (ICO), the contact details of which are
        available here: https://ico.org.uk/global/contact-us/
      </p>

      <p>
        Further information on your rights in relation to your personal data as
        an individual
      </p>

      <p>
        The above rights are provided in summary form only and certain
        limitations apply to many of these rights. For further information about
        your rights in relation to your information, including any limitations
        which apply, please visit the following pages on the ICO&rsquo;s
        website:
      </p>

      <p>
        &middot;
        https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/;
        and
      </p>

      <p>
        &middot;
        https://ico.org.uk/for-the-public/is-my-information-being-handled-correctly/
      </p>

      <p>
        You can also find out further information about your rights, as well as
        information on any limitations which apply to those rights, by reading
        the underlying legislation contained in Articles 12 to 22 and 34 of the
        General Data Protection Regulation, which is available here:
        http://ec.europa.eu/justice/data-protection/reform/files/regulation_oj_en.pdf
      </p>

      <p>
        Verifying your identity where you request access to your information
      </p>

      <p>
        Where you request access to your information, we are required by law to
        use all reasonable measures to verify your identity before doing so.
      </p>

      <p>
        These measures are designed to protect your information and to reduce
        the risk of identity fraud, identity theft or general unauthorised
        access to your information.
      </p>

      <p>How we verify your identity</p>

      <p>
        Where we possess appropriate information about you on file, we will
        attempt to verify your identity using that information.
      </p>

      <p>
        If it is not possible to identity you from such information, or if we
        have insufficient information about you, we may require original or
        certified copies of certain documentation in order to be able to verify
        your identity before we are able to provide you with access to your
        information.
      </p>

      <p>
        We will be able to confirm the precise information we require to verify
        your identity in your specific circumstances if and when you make such a
        request.
      </p>

      <p>
        Your right to object to the processing of your information for certain
        purposes
      </p>

      <p>
        You have the following rights in relation to your information, which you
        may exercise in the same way as you may exercise by sending an email to
        hello@sidewalkbar.co.uk:
      </p>

      <p>
        &middot; to object to us using or processing your information where we
        use or process it in order to carry out a task in the public interest or
        for our legitimate interests, including &lsquo;profiling&rsquo; (i.e.
        analysing or predicting your behaviour based on your information) based
        on any of these purposes; and
      </p>

      <p>
        &middot; to object to us using or processing your information for direct
        marketing purposes (including any profiling we engage in that is related
        to such direct marketing).
      </p>

      <p>
        You may also exercise your right to object to us using or processing
        your information for direct marketing purposes by:
      </p>

      <p>
        &middot; clicking the unsubscribe link contained at the bottom of any
        marketing email we send to you and following the instructions which
        appear in your browser following your clicking on that link;
      </p>

      <p>
        &middot; sending an SMS message containing only the words
        &ldquo;STOP&rdquo; in reply to any marketing communication we send by
        text message;
      </p>

      <p>
        &middot; sending an email to hello@sidewalkbar.co.uk asking that we stop
        sending you marketing communications or by including the words
        &ldquo;OPT OUT&rdquo;.
      </p>

      <p>
        For more information on how to object to our use of information
        collected from cookies and similar technologies, please see the section
        entitled How to accept or reject cookies in our cookies policy
      </p>

      <p>Sensitive Personal Information</p>

      <p>
        &lsquo;Sensitive personal information&rsquo; is information about an
        individual that reveals their racial or ethnic origin, political
        opinions, religious or philosophical beliefs, or trade union membership,
        genetic information, biometric information for the purpose of uniquely
        identifying an individual, information concerning health or information
        concerning a natural person&rsquo;s sex life or sexual orientation.
      </p>

      <p>
        We do not knowingly or intentionally collect sensitive personal
        information from individuals, and you must not submit sensitive personal
        information to us.
      </p>

      <p>
        If, however, you inadvertently or intentionally transmit sensitive
        personal information to us, you will be considered to have explicitly
        consented to us processing that sensitive personal information under
        Article 9(2)(a) of the General Data Protection Regulation. We will use
        and process your sensitive personal information for the purposes of
        deleting it.
      </p>

      <p>Changes to our Privacy Policy</p>

      <p>We update and amend our Privacy Policy from time to time.</p>

      <p>Minor changes to our Privacy Policy</p>

      <p>
        Where we make minor changes to our Privacy Policy, we will update our
        Privacy Policy with a new effective date stated at the beginning of it.
        Our processing of your information will be governed by the practices set
        out in that new version of the Privacy Policy from its effective date
        onwards.
      </p>

      <p>
        Major changes to our Privacy Policy or the purposes for which we process
        your information
      </p>

      <p>
        Where we make major changes to our Privacy Policy or intend to use your
        information for a new purpose or a different purpose than the purposes
        for which we originally collected it, we will notify you by email (where
        possible) or by posting a notice on our website.
      </p>

      <p>
        We will provide you with the information about the change in question
        and the purpose and any other relevant information before we use your
        information for that new purpose.
      </p>

      <p>
        Wherever required, we will obtain your prior consent before using your
        information for a purpose that is different from the purposes for which
        we originally collected it.
      </p>

      <p>Children&rsquo;s Privacy</p>

      <p>
        Because we care about the safety and privacy of children online, we
        comply with the Children&rsquo;s Online Privacy Protection Act of 1998
        (COPPA). COPPA and its accompanying regulations protect the privacy of
        children using the internet. We do not knowingly contact or collect
        information from persons under the age of 18. The website is not
        intended to solicit information of any kind from persons under the age
        of 18.
      </p>

      <p>
        It is possible that we could receive information pertaining to persons
        under the age of 18 by the fraud or deception of a third party. If we
        are notified of this, as soon as we verify the information, we will,
        where required by law to do so, immediately obtain the appropriate
        parental consent to use that information or, if we are unable to obtain
        such parental consent, we will delete the information from our servers.
        If you would like to notify us of our receipt of information about
        persons under the age of 18, please do so by sending an email to
        data@barfibre.com.
      </p>

      <p>Copyright, credit and logo</p>

      <p>
        This Privacy Policy is based on a General Data Protection Regulation
        (Regulation (EU) 2016/769) (GDPR) compliant template provided by GDPR
        Privacy Policy. For further information, please visit
        https://gdprprivacypolicy.org
      </p>

      <p>
        The copyright in this Privacy Policy is either owned by, or licensed to,
        us and is protected by copyright laws around the world and copyright
        protection software. All intellectual property rights in this document
        are reserved.
      </p>

      <p>
        Where we display the GDPR Privacy Policy logo on our website, this is
        used to indicate that we have adopted a privacy policy template provided
        by GDPR Privacy Policy as the basis for this Privacy Policy.
      </p>
    </div>
  );
}
export default Privacy;
