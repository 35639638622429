import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import deleteIcon from "../../../images/delete.png";
function OfferTimes(props) {
  const [data, setData] = useState([]);

  function formatDay(day) {
    switch (day) {
      case "1":
        return "Monday";
        break;
      case "2":
        return "Tuesday";
        break;
      case "3":
        return "Wednesday";
        break;
      case "4":
        return "Thursday";
        break;
      case "5":
        return "Friday";
        break;
      case "6":
        return "Saturday";
        break;
      case "7":
        return "Sunday";
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (props.data?.current_value) {
      console.log(props?.data?.current_value);

      setData(JSON.parse(props?.data?.current_value) ?? null);
    }
  }, [props.data]);

  function deleteItem(index) {
    console.log(index);

    data.splice(index, 1);
    console.log(data);
    props.value_changed({
      value: JSON.stringify(data),
      index: props.index,
    });
  }

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    if (props.data.current_value) {
      var array = JSON.parse(props.data?.current_value);
    } else {
      var array = [];
    }
    array.push(inputs);
    props.value_changed({
      value: JSON.stringify(array),
      index: props.index,
    });
  };

  return (
    <div className="container">
      <Form onSubmit={handleSubmit}>
        <div className="row" style={{ paddingBottom: 15 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Control
              as="select"
              name="start_day"
              style={{ marginRight: 10 }}
              onChange={handleChange}
            >
              <option value="-"></option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
              <option value="7">Sunday</option>
            </Form.Control>
            <Form.Control
              name="start_time"
              onChange={handleChange}
              type="text"
              maxLength={5}
              placeholder="10:00"
              style={{ width: "70px" }}
            ></Form.Control>

            <div
              style={{
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              to
            </div>
            <Form.Control
              name="end_day"
              as="select"
              style={{ marginRight: 10 }}
              onChange={handleChange}
            >
              <option value="-"></option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
              <option value="7">Sunday</option>
            </Form.Control>
            <Form.Control
              name="end_time"
              onChange={handleChange}
              maxLength={5}
              style={{ width: "70px" }}
              type="text"
              placeholder="10:00"
            ></Form.Control>
            <Button
              type={"submit"}
              style={{ marginLeft: 10 }}
              variant="success"
            >
              Add
            </Button>
          </div>
        </div>
        {data &&
          data?.map(function (item, i) {
            return (
              <div className="row" style={{ fontWeight: 600 }}>
                {formatDay(item.start_day)} {item.start_time} to{" "}
                {formatDay(item.end_day)} {item.end_time}
                <img
                  onClick={() => deleteItem(i)}
                  src={deleteIcon}
                  style={{
                    height: 15,
                    width: 15,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                />
              </div>
            );
          })}
      </Form>
    </div>
  );
}
export default OfferTimes;
