import React, { useState } from "react";
import { Container, Row, Form, Button } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import styles from "./Notifications.module.css";
import ImageUpload from "../Components/Tables/CustomComponents/ImageInput";
import axiosConfig from "../axiosConf";

function Notifications() {
  const [title, setTitle] = useState("");
  const [pushText, setPushText] = useState("");
  const [topImage, setTopImage] = useState("");
  const [body, setBody] = useState("");
  const [middleImage, setMiddleImage] = useState("");
  const [extText, setExtText] = useState("");

  const submit = () => {
    if (title === "") {
      alert("Title cannot be empty");
      return;
    }
    if (pushText === "") {
      alert("Push body cannot be empty");
      return;
    }

    try {
      axiosConfig
        .post("/send_notification.php", {
          title: title,
          body: pushText,
        })
        .then((response) => {
          console.log(response.status);
          alert("Push Notification Sent.");
        })
        .catch((error) => {
          console.log(error);
          alert("An error occured.");
        });
    } catch (error) {
      console.log(error);
      alert("An error occured.");
    }
  };

  return (
    <Container className={styles["main-container"]} fluid>
      <Row>
        <Header1 title={"Notifications"} />
      </Row>

      <Container className={styles["inner-container"]} fluid>
        <Form>
          <Form.Group controlId="formBasicText">
            <Form.Label>Push Notification Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Push Title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <Form.Text className="text-muted">
              This text will appear in the title of the push notification
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Label>Push Notification Text</Form.Label>
            <Form.Control
              type="text"
              placeholder="Push Body"
              onChange={(e) => setPushText(e.target.value)}
            />
            <Form.Text className="text-muted">
              This text will appear in the push notification on peoples devices.
            </Form.Text>
          </Form.Group>

          <Button variant="primary" onClick={() => submit()}>
            Submit
          </Button>
        </Form>
      </Container>
    </Container>
  );
}
export default Notifications;
