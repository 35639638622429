import React from "react";
import { Container, Row } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./viewcustomer.module.css";
import Claimed from "../Components/Tables/CustomComponents/Claimed";
import axios from "axios";
import axiosConfig from "../axiosConf";

function Entries() {
  const components = {
    Claimed: Claimed,
  };

  const headers = [
    { title: "Name", value: "name", editable: false },
    { title: "Email", value: "email", editable: false },
    {
      title: "Competition",
      value: "title",
      editable: false,
    },
    { title: "Time", value: "time", editable: false },
  ];

  function download() {
    try {
      axiosConfig
        .post("/entriescsv.php", { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `downloaded Report.csv`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
          alert("An error occured.");
        });
    } catch (error) {
      console.log(error);
      alert("An error occured.");
    }
  }

  return (
    <Container className={styles["main-container"]} fluid>
      <Row>
        <Header1
          title={"Competition Entries"}
          buttonText={"Download CSV"}
          showModal={(e) => [download()]}
        />
      </Row>
      <Row className={styles["table-row"]}>
        <Table
          components={components}
          headers={headers}
          getEP={"/get_entries.php"}
        />
      </Row>
    </Container>
  );
}
export default Entries;
