import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";
import FoodCategoryDropDown from "../Components/Tables/CustomComponents/FoodCategoryDropDown";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
  FoodCategoryDropDown: FoodCategoryDropDown,
};

function FoodMenu() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Name",
      value: "name",
      editable: true,
      place_holder: "Product Name",
      modal_component: "Text",
    },
    {
      title: "Description",
      value: "description",
      editable: true,
      place_holder: "Product Description",
      modal_component: "TextArea",
    },
    {
      title: "Image",
      value: "image",
      editable: true,
      place_holder: "image",
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
    {
      title: "Calories",
      value: "calories",
      editable: true,
      place_holder: "0",
      modal_component: "Text",
    },
    {
      title: "Category",
      value: "category",
      editable: true,
      hidden: true,
      place_holder: "Mains",
      modal_component: "FoodCategoryDropDown",
    },
    {
      title: "Servings",
      value: "servings",
      editable: true,
      place_holder: "1-2",
      modal_component: "Text",
    },
    {
      title: "Price",
      value: "price",
      editable: true,
      place_holder: "0.5",
      modal_component: "Text",
    },
    {
      title: "Veggie",
      value: "veggie",
      editable: true,
      place_holder: "0",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Name",
        value: "name",
        editable: true,
        place_holder: "Product Name",
        modal_component: "Text",
      },
      {
        title: "Description",
        value: "description",
        editable: true,
        place_holder: "Product Description",
        modal_component: "TextArea",
      },
      {
        title: "Image",
        value: "image",
        editable: true,
        place_holder: "image",
        modal_component: "Image",
        custom_component: "ImagePreview",
      },
      {
        title: "Calories",
        value: "calories",
        editable: true,
        place_holder: "0",
        modal_component: "Text",
      },
      {
        title: "Category",
        value: "category",
        editable: true,
        hidden: true,
        place_holder: "Mains",
        modal_component: "FoodCategoryDropDown",
      },
      {
        title: "Servings",
        value: "servings",
        editable: true,
        place_holder: "1-2",
        modal_component: "Text",
      },
      {
        title: "Price",
        value: "price",
        editable: true,
        place_holder: "0.5",
        modal_component: "Text",
      },
      {
        title: "Veggie",
        value: "veggie",
        editable: true,
        place_holder: "0",
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Food Menu"}
            buttonText={"Add Product"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deletefood.php"}
            getEP={"/food.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addfood.php"}
          deleteEP={"/deletefood.php"}
          editEP={"/updatefood.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default FoodMenu;
