import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";
import CocktailCategoryDropDown from "../Components/Tables/CustomComponents/CocktailCategoryDropDown";
import CocktailsCategoryPreview from "../Components/Tables/CustomComponents/CocktailsCategoryPreview";
import CheckBox from "../Components/Tables/CustomComponents/CheckBox";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
  CocktailCategoryDropDown: CocktailCategoryDropDown,
  CocktailsCategoryPreview: CocktailsCategoryPreview,
  CheckBox: CheckBox,
};

function DrinkMenu() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Name",
      value: "name",
      editable: true,
      place_holder: "Drink Name",
      modal_component: "Text",
    },
    {
      title: "Image",
      value: "image",
      editable: true,
      place_holder: "",
      modal_component: "Image",
      custom_component: "ImagePreview",
    },

    {
      title: "Description",
      value: "description",
      editable: true,
      place_holder: "Description",
      modal_component: "Text",
    },
    {
      title: "Price",
      value: "price",
      editable: true,
      place_holder: "£1.00",
      modal_component: "Text",
    },
    {
      title: "Category",
      value: "category",
      editable: true,
      hidden: true,
      place_holder: "spirits",
      modal_component: "CocktailCategoryDropDown",
    },
    {
      title: "Promo Text",
      value: "promo",
      editable: true,
      place_holder: "",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Name",
        value: "name",
        editable: true,
        place_holder: "Drink Name",
        modal_component: "Text",
      },
      {
        title: "Image",
        value: "image",
        editable: true,
        place_holder: "",
        modal_component: "Image",
        custom_component: "ImageInput",
      },

      {
        title: "Description",
        value: "description",
        editable: true,
        place_holder: "Description",
        modal_component: "Text",
      },
      {
        title: "Price",
        value: "price",
        editable: true,
        place_holder: "£1.00",
        modal_component: "Text",
      },
      {
        title: "Category",
        value: "category",
        editable: true,
        place_holder: "spirits",
        modal_component: "CocktailCategoryDropDown",
      },
      {
        title: "Two for £12",
        value: "promo",
        editable: true,
        place_holder: "",
        modal_component: "CheckBox",
      },
      {
        title: "Two for £14",
        value: "promo2",
        editable: true,
        place_holder: "",
        modal_component: "CheckBox",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Drink Menu"}
            buttonText={"Add Drink"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deletedrink.php"}
            getEP={"/getdrinks.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => [setUpdateData(updateData + 1)]}
          addEP={"/adddrink.php"}
          deleteEP={"/deletedrink.php"}
          editEP={"/updatedrink.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default DrinkMenu;
