import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
};

function AboutUs() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Image",
      value: "image",
      editable: true,
      place_holder: "",
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
    {
      title: "Description",
      value: "description",
      editable: true,
      place_holder: "Text description",
      modal_component: "Text",
    },
    {
      title: "Address",
      value: "address",
      editable: true,
      place_holder: "1 Low Road, London, NW20 6YH",
      modal_component: "Text",
    },
    {
      title: "email",
      value: "email",
      editable: true,
      place_holder: "hello@support.com",
      modal_component: "Text",
    },
    {
      title: "Phone",
      value: "phone",
      editable: true,
      place_holder: "0201 111 111",
      modal_component: "Text",
    },
    {
      title: "Instagram",
      value: "instagram",
      editable: true,
      place_holder: "https://instagram.com/username",
      modal_component: "Text",
    },
    {
      title: "Facebook",
      value: "facebook",
      editable: true,
      place_holder: "https://facebook.com/username",
      modal_component: "Text",
    },
    {
      title: "TikTok",
      value: "tiktok",
      editable: true,
      place_holder: "https://tiktok.com/username",
      modal_component: "Text",
    },
    {
      title: "Website",
      value: "website",
      editable: true,
      place_holder: "https://myvenue.com",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"About Us"}
            buttonText={""}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={""}
            getEP={"/getaboutus.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={""}
          deleteEP={""}
          editEP={"/updateaboutus.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default AboutUs;
