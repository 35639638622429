import { useEffect, useState } from "react";

function DayDropDownPreview(props) {
  const [day, setDay] = useState("");

  useEffect(() => {
    switch (props.data) {
      case 1:
        setDay("Monday");
        break;
      case 2:
        setDay("Tuesday");
        break;
      case 3:
        setDay("Wednesday");
        break;
      case 4:
        setDay("Thursday");
        break;
      case 5:
        setDay("Friday");
        break;
      case 6:
        setDay("Saturday");
        break;
      case 7:
        setDay("Sunday");
        break;
      default:
        setDay("-");
    }
  }, [props]);

  return <div>{day}</div>;
}
export default DayDropDownPreview;
